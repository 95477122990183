import React from 'react'
import loading from '../images/loading.gif'

function LoadingPage() {
  return (
    <div style={{'display':'flex','flexDirection':'column','justifyContent':'center'}}>
      <div style={{'marginTop':'150px'}}></div>
      <img src={loading}  style={{'height':'200px','width':'200px','marginInline':'auto'}}/>
       
       <h5 style={{'marginTop':'40px'}}>Loading Please Wait</h5>
    </div>
  )
}

export default LoadingPage
