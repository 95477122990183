import React from 'react'
import Login from '../Login'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Register from '../Register'

function RegisterPage() {
  return (
    <div className='App' >
        <Header/>
     <div className='main__section' style={{maxWidth:'500px',margin:'auto',paddingInline:'20px'}}>
        <Register isRegisterPage={true}/>   
        </div>  
       <Footer/>
    </div>
  
  )
}

export default RegisterPage
