import React from 'react'
import ProductsCarouselCarousel from './ProductsCarousel'
import Category from './Category'
import HeroProducts from './HeroProducts'
import CTA from './CTA'
import OurStory from './OurStory'
import TopSelling from './TopSelling'
import Testimonial from './Testimonial'
import ProductBanners from './ProductBanners'
import ProductCarousel from './ProductsCarousel'
import HeroCarousel from './HeroCarousel'
 

function Body() {
  return (
    <div>
      <HeroCarousel/>
      <Category/> 
      <HeroProducts/>
      <ProductBanners/>
      <OurStory/>
      <TopSelling/>
      <Testimonial/>
      <CTA/>
    </div>
  )
}

export default Body
