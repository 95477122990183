import React from 'react'
import e404 from '../images/e404.png'

function Error404() {
  return (
  <div style={{'display':'flex','flexDirection':'column','justifyContent':'center'}}>
     
    <img src={e404}  style={{'height':'300px','width':'300px','marginInline':'auto'}}/>
    <h2 style={{'marginTop':'40px','fontSize':'32px','padding':'12px'}}>Page your are looking for is not found</h2>
  </div>
  )
}

export default Error404
