import React, { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import Header from '../Header/Header';
import loading from '../images/loading.gif'
import LoadingPage from './LoadingPage';
import Error404 from './Error404';
import Footer from '../Footer/Footer';
import axios from 'axios';
import ProductDetails from './ProductDetails';

 
 


function SingleProducts(props) {
  const { any } = useParams();
  const [product, setProduct] = useState([]);
  const [loading , setLoading] = useState(true)

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.ekanaherbs.com/api/v1/get-product/${any}`);
      setProduct(response.data); // Assuming the data is an array
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (

    <div className='App'>
      
      <Header/>
      <Suspense fallback={<div ><LoadingPage/></div> }>
      <div className='main__section'>
      
      {!loading && product.length <1 && <Error404/>}
      {product.length > 0 &&  
      product.map((product) => <ProductDetails product={product}/>)
       }


      </div>
</Suspense>
       
      <Footer/>
      
      {/* <LoadingPage/> */}
       
       
    </div>
  )
}

export default SingleProducts
