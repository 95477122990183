import { ArrowBackIcon, ArrowForwardIcon, MinusIcon, PlusSquareIcon } from '@chakra-ui/icons'
import {    useToast , Button, CloseButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, Badge,  } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

function OfferCartItem(props) {
    
    const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast();
  const [addedQuan,setAddedQuan]=useState(0);
  
  const handleQuanIncrementor=(offer)=>{
    if(addedQuan<40){

setAddedQuan(addedQuan+1);
const cart = JSON.parse(localStorage.getItem('cart')) || [];
   
const foundIndex = cart.findIndex(item => item.product === offer.id);

if (foundIndex !== -1) { 
    cart[foundIndex].quantity += 1; 
} else { 
    const newItem = {
        'product': offer.id,
        'quantity': 1, 
    };
    cart.push(newItem);
}
localStorage.setItem('cart', JSON.stringify(cart));
    toast({
      title: `Added 1 more quanity of ${offer.offer_title}`, 
      status: 'success',
      duration: 1000,
      isClosable: true,
    })
    }else{
      toast({
        title: `Cannot Add more than 40`, 
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
    }
    
    
  }
  const [cartItems, setCartItems] = useState([]);
  const handleRemoveFromCart = (productId) => {
  // Filter out the item to be removed based on its productId
  const updatedCart = cartItems.filter(item => item.product !== productId);
  
  // Update the local storage and state
  localStorage.setItem('cart', JSON.stringify(updatedCart));
  setCartItems(updatedCart); 
  onClose()
};
  const handleQuanDecrementor=(offer)=>{
    if(addedQuan>1){
      const cart = JSON.parse(localStorage.getItem('cart')) || [];
   
const foundIndex = cart.findIndex(item => item.product === offer.id  );

if (foundIndex !== -1) { 
    cart[foundIndex].quantity -= 1; 
} else { 
    const newItem = {
        'product': offer.id,
        'quantity': 1, 
    };
    cart.push(newItem);
}
localStorage.setItem('cart', JSON.stringify(cart));
      setAddedQuan(addedQuan-1);

      toast({
        title: `Removed 1  quanity of ${offer.offer_title}`, 
        status: 'success',
        duration: 1000,
        isClosable: true,
      })
    }else{
      toast({
        title: `Cannot Remove`, 
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
    }
    
     
  }
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || []; 
    setCartItems(cart)
    const found = cart.find(item => item.product === props.offer.id  );
    if (found) {
      setAddedQuan(cart.find(item => item.product === props.offer.id  && item.type=="offer").quantity) 
    }
  }, []);
  return (
    <div className='p-1'>
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(10deg)'
    />
        <ModalContent>
          <ModalHeader>
            Remove From Cart</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text style={{fontWeight:'bolder',fontSize:'20px'}}>Are you sure you want to remove  this  from cart</Text>
          </ModalBody>
          <ModalFooter>
            <Button   onClick={()=> handleRemoveFromCart(props.kid)}>Yes</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <div  className='row d-flex  align-items-center' style={{backgroundColor:'#f4f4f4'}}>
    <div className='col-md-12 d-flex justify-content-end' style={{float:'right'}}>
       <CloseButton onClick={()=>onOpen()}/> 
      </div>
      <div className="col-md-3 col-sm-6">
        <img src={`https://portal.ekanaherbs.com/custom/images/products/${props.offer.offer_image}`} style={{height:150,width:150, borderRadius:'50%'}}/>
      </div>
      <div className='col-md-9'>
        <div className='row d-flex align-items-center'>
            <div className='col-md-4 text-left'>
                <p style={{fontWeight:'bolder'}}>
                    {props.offer.offer_title} 
                </p>
                <p style={{fontWeight:'bolder'}}>
                {props.offer.category_name}
                </p>
                <p style={{fontWeight:'normal'}}>
                    Quantity :  {props.quantity} Unit(s)
                </p>
               
               
                
            </div>
            <div className='col-md-4 addSubs'  >
                 <ArrowBackIcon boxSize={6} onClick={()=>handleQuanDecrementor(props.offer)}  /> 
                  
                 <span className='quan'>{addedQuan}</span><ArrowForwardIcon boxSize={6}  onClick={()=>handleQuanIncrementor(props.offer)}  />
            </div>
            <div className='col-md-4'>
                <span style={{fontSize:'25px',fontWeight:500}}>₹ {addedQuan*props.offer.offer_price}/-</span> 
                <p style={{textAlign:'center'}}>
                 <Badge colorScheme='purple'>Offer Item</Badge>   
                </p>
                
            </div>
        </div>
      </div>
      
      
    </div>

    
    </div>
  )
}

export default OfferCartItem
