import { Button } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import img from './../../images/vision-1.jpg'

function Vision() {
  return (
     
      <div className='row d-flex justify-content-center'>
        <div className='col-md-6'>
          <img style={{height:'500px'}} src={img}/>
        </div>
        <div className='col-md-6 align-items-center' style={{paddingTop:'100px'}}>
          <h1 className='col-md-12' style={{fontWeight:'bolder'}}>Our Vision</h1>
          <h4 className='col-md-12'>Our vision is to enable people to live financially
independent on their own terms. </h4>
<p>At Ekana Herbs Private Limited, our unwavering vision is to usher in a future where people have the tools and knowledge to live financially independent lives, defined by their unique aspirations and goals. We are committed to providing individuals with the resources and support they need to take control of their financial well-being, enabling them to make informed decisions and chart their own path to independence.</p>
<br/>
<Button colorScheme='teal'><Link to="/start-a-bussines">Read More</Link> </Button>
        </div>
        
        
      </div>  
  )
}

export default Vision
