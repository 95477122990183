import React from 'react'
import TestimonialCarousel from './TestimonialCarousel'

function Testimonial() {
  return (
    <div className='testimonial'>
      <h2>What People  Have To Say About Us</h2>
      <br/>
      <TestimonialCarousel/>
    </div>
  )
}

export default Testimonial
