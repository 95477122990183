import React from 'react'

function TestimonialCard(props) {
  return (
    <div className='testimonial__card'>
    <ul>
      <li>
        <div className='card__body'>
          <img src={props.image}  style={{width:'100px',height:'100px',borderRadius:'50px',margin:'auto'}}/>
          <h3>{props.imageName}</h3>
          <p>
            <em>
           {props.desc}
            </em>
          </p>
        </div>
      </li>
     
    </ul>
    </div>
  )
}

export default TestimonialCard
