import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

function Faq() {
  return (
    <div className='App'>
      <Header/>
      <div className='text-center main__section'>
        <h1>FAQ Page</h1>
      </div>
      <Footer/>
    </div>
  )
}

export default Faq
