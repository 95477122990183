import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header/Header'
import crypto from 'crypto-js';
import Footer from '../Footer/Footer'
import axios from 'axios';
import RemoveIcon from '../svgs/RemoveIcon';
import LoadingPage from '../Products/LoadingPage';
import Error404 from '../Products/Error404';
 
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  Button,
  FormLabel,
  Badge,
  Checkbox,
  FormControl,
} from '@chakra-ui/react'
import { 
  useToast,
} from '@chakra-ui/react'
import { ArrowForwardIcon, EditIcon } from '@chakra-ui/icons';
import CartItem from './CartItem';
import OfferItem from '../Body/OfferItem';
import OfferCartItem from './OfferCartItem';
import { Link } from 'react-router-dom';
import Login from '../Login';
import RegisterPage from '../Pages/RegisterPage';
import Register from '../Register';
import { useAuthContext } from '../auth/Auth'; 
import Confetti from 'react-confetti'
import ScrollToTop from '../components/ScrollToTop';



 
function MyCart() { 
   
  const toast = useToast()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [lastPaymentMessage,setLastPaymentMessage]=useState('')
   
  const [authPage,setAuthPage]=useState('login');
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const [mycart,setMyCart]=useState([]);
  const [products , setProducts]= useState([]);
  const [offers , setOffers]= useState([]);
  const [loading , setLoading] = useState(true)
  const [productPrice,setProductPrice]= useState(0);
  const [lastPayedAmount,setLastPayedAmount]= useState(0);
  const [tax,setTax]=useState(0);
  const signin= useAuthContext();
  const [orderId,setOrderId]=useState(null);
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);
   
  const [selectedOffice,setSelectedOffice]=useState();


  const [paymentStatus,setPaymentStatus]=useState(false);
  const officeSet=()=>{
    const selOffice = JSON.parse(localStorage.getItem('selectedOffice')) || [];
    setSelectedOffice(selOffice);
  }
  const handlePayment = async (status, orderId, paymentId, razorpaySignature) => {
    try {
      const token = localStorage.getItem('token') ?? 'no-token';
  
      // Make a POST request to validate the payment status
      const paymentStatusResponse = await axios.post(
        'https://api.ekanaherbs.com/api/v1/payments/razorpay/order/validate',
        {
          'status': status,
          'order_id': orderId,
          'payment_id': paymentId,
          'payment_signature': razorpaySignature,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );
   
      if(paymentStatusResponse.data['response']){
        setPaymentStatus(paymentStatusResponse.data['response']);
        onClose();
        setLastPaymentMessage('');
        
        localStorage.removeItem('cart')
      }else{
        setLastPaymentMessage('Your Last Payment was Unsuccessful');
        toast({
          title: paymentStatusResponse.data['message'], 
          status: 'warning',
          duration: 4000,
          position:'top',
          isClosable: true,
        });
      }
    
    } catch (error) {
      // Handle errors
      
    }
  };

  const sendCreateOrder= async()=>{
    try {
      const user=JSON.parse(localStorage.getItem('user'));
      if(localStorage.getItem('selectedOffice')==null){
        toast({
          title: 'Please Set Your Address from Top', 
          status: 'success',
          duration: 2000,
          position:'top',
          isClosable: true,
        });
        return;
      }
      onClose();
      toast({
        title: 'Redirecting Please Wait...', 
        status: 'success',
        duration: 2000,
        position:'top',
        isClosable: true,
      })
      //final amount count
      var address=selectedOffice??'';
      var pincode=selectedOffice.Pincode??'';
      const token = localStorage.getItem('token') ?? 'no-token';    
      const createOrderResponse = await axios.post('https://api.ekanaherbs.com/api/v1/payments/razorpay/order/create',
      {
        'cart':mycart,
        'address':JSON.stringify(address),
        'pincode':pincode,         

      },
      {headers: {
        'Authorization': `Bearer ${token}`,
        
      }
     });
     setOrderId(createOrderResponse.data);
     localStorage.setItem('orderId',createOrderResponse.data);
     //alert(createOrderResponse.data);
     //Razorpay stuffs
     if(createOrderResponse.data==''){
      toast({
        title: 'Order Id Empty error', 
        status: 'warning',
        duration: 2000,
        position:'top',
        isClosable: true,
      })
      return;
     }
      const orderIdRecieved= localStorage.getItem('orderId');
     const productPriceInt = parseInt(productPrice);
    
      const shipping = productPriceInt > 1500 ? 0 : 100;
     const options = {
      "key": process.env.REACT_APP_RAZORPAY_SECRET_KEY, // Enter the Key ID generated from the Dashboard
      "amount":productPriceInt+shipping, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Ekana Herbs Pvt Ltd", // Your business name
      "description": "Test Transaction",
      "image": "https://i.ibb.co/1qcVdkK/ekana-lo.png",
      "order_id": orderIdRecieved,  
      
      "prefill": { // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          "name": user.name??'Gaurav Kumar', // Your customer's name
          "email": user.email??'ekanaherbs@gmail.com',
          "contact":user.phone??'9090909090' // Provide the customer's phone number for better conversion rates 
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#946c24"
      },
      "handler": (response) => {
        
        paymentId.current = response.razorpay_payment_id;
        const orderId= localStorage.getItem('orderId');
       
        // Most important step to capture and authorize the payment. This can be done of Backend server.
        
         // If successfully authorized. Then we can consider the  
          handlePayment('succeeded',orderId,response.razorpay_payment_id,response.razorpay_signature,
          );
         
          
      },
      "modal": {
        "confirm_close": true, // this is set to true, if we want confirmation when clicked on cross button.
        // This function is executed when checkout modal is closed
        // There can be 3 reasons when this modal is closed.
        ondismiss: async (reason) => {
          const {
            reason: paymentReason, field, step, code,
          } = reason && reason.error ? reason.error : {};
          // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly. 
          if (reason === undefined) {
            toast({
              title: 'Payment Canceled By User', 
              status: 'warning',
              duration: 2000,
              position:'top',
              isClosable: true,
            })
            const orderId= localStorage.getItem('orderId');
            handlePayment('Cancelled',orderId);
          } 
          // Reason 2 - When modal is auto closed because of time out
          else if (reason === 'timeout') {
            const orderId= localStorage.getItem('orderId');
            toast({
              title: 'Payment Timeout', 
              status: 'warning',
              duration: 2000,
              position:'top',
              isClosable: true,
            })
            handlePayment('timedout',orderId);
          } 
          // Reason 3 - When payment gets failed.
          else {
            toast({
              title: 'Payment Failed', 
              status: 'warning',
              duration: 2000,
              position:'top',
              isClosable: true,
            })
            const orderId= localStorage.getItem('orderId');
            handlePayment('failed',orderId);
          }
        },
      },
      // This property allows to enble/disable retries.
      // This is enabled true by default. 
      "retry": {
        "enabled": false,
      },
  };


  const rzp1 = new window.Razorpay(options);      

  rzp1.open(options);
 
   
  rzp1.on('payment.failed', function (response){
 
  })

  rzp1.on('payment.submit', function (resp){
    
    
     
    })
    } catch (error) {
      
    }
    



  }
  const fetchData = async () => {
    try {
     
      const responseOfProducts = await axios.get('https://api.ekanaherbs.com/api/v1/all-products');
      setProducts(responseOfProducts.data);
      
    
     const responseOfOffers = await axios.get('https://api.ekanaherbs.com/api/v1/all-offers');
      
     setOffers(responseOfOffers.data);
       setLoading(false)
    } catch (error) {
      
    }

  };
  const handleQuantityChange = (productId, event) => {
    const updatedCartCopy = [...mycart];
    const index = updatedCartCopy.findIndex((item) => item.product === productId);
    if(parseInt(event.target.value)<1 || parseInt(event.target.value)>40){
      toast({
        title: 'Invalid Value Please enter quantity from 1 to 40.', 
        status: 'warning',
        duration: 2000,
        position:'top',
        isClosable: true,
      })
      return ;
    }
    if (index !== -1) {
      updatedCartCopy[index].quantity = event.target.value;
      setMyCart(updatedCartCopy);
    }
  };
 useEffect(()=>{
  fetchData();
    officeSet();
 },[])  
   
  useEffect(()=>{
     
    const carts = JSON.parse(localStorage.getItem('cart')) || [];
    let price=0;
    setMyCart(carts);
    let gst=0;
    
    carts.forEach(element => {
      const product = products.find((prod) => prod.id === element.product && element.type=="product"); 
      if (product) {

        const productPrice2= product.product_price;
        const gstPerc = product.gst;
        price +=element.quantity*productPrice2;
        gst += parseInt(productPrice2*gstPerc*element.quantity/100)
        
         
      }
      const offer = offers.find((prod) => prod.id === element.product && element.type==="offer");
      if(offer){
        const productPrice2= offer.offer_price;
        const gstPerc = 18;
        price +=element.quantity*productPrice2;
        gst += parseInt(productPrice2*gstPerc*element.quantity/100)
      }
      
    });
    if(carts.length>0){
     setProductPrice(price);
    localStorage.setItem('price',price) 
    }
    
    setTax(gst);
  
  },[mycart])
   
  
   
  // };
  return (
    
    <div className='App'>
     <Header/>
      
       <div className='main__section container'>

      {paymentStatus && (<>
      <ScrollToTop/>
     <Confetti
     style={{zIndex:'1000101'}}
      width={window.innerWidth}
      height={window.innerHeight}
    />
    <div style={{height:'100px'}}/>
    <Alert
    status='success'
    variant='subtle'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    textAlign='center'
    height=''
    maxWidth='600px'
    marginInline='auto'
  >
    <AlertIcon boxSize='40px' mr={0} />
    <AlertTitle mt={4} mb={1} fontSize='lg'>
      <h2> ₹ {parseInt(productPrice+(productPrice>1500?0:100))}</h2>
      Order Place successfully!
    </AlertTitle>
    <AlertDescription maxWidth='sm'>
      <p>Thanks for your order. We have recieved your payment confirmation and will notify you from time to time via email.</p>
      <br></br>
      <p>Please check your orders from here click view my orders button to view your order list</p>
      <Button style={{marginTop:'50px'}}>
      <Link to='/dashboard'>
        View My Orders
      </Link>

      </Button>
    </AlertDescription>
  </Alert>
    </>)}{ !paymentStatus &&(
    <> 
       <div className='bradcrumb'>Home / <strong>Cart</strong>
       </div>
      <div className='smooth__header smooth__header__info  ' style={{height:'200px',backgroundColor:'#deb77066'}}>
           <h4>Your Cart</h4>
           <div className='search__container'>
            
           </div>

            
      </div> 
      {lastPaymentMessage!=''&&(<Alert status='error'>
    <AlertIcon />
    {lastPaymentMessage}
  </Alert>)}
       

      {loading && <LoadingPage />}
      {!loading && products.length <1 && <Error404/>}
      {!loading && mycart.length<1 && (<div className='text-center m-auto'><img style={{margin:'auto'}} src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"/> <p style={{fontSize:'30px',textAlign:'center'}}>Your Cart Is Empty</p></div>)}
      <div class="row d-flex">
        <div class="col-md-8">
        
         { products.length>0   &&  mycart.map((carts,key)=>(
            carts.type=="product"?    
                (<CartItem key={carts.product} kid={carts.product}   quantity={carts.quantity}  product={products.find((product)=>product.id==carts.product)}/>):<OfferCartItem  kid={carts.product}  key={key} quantity={carts.quantity}  offer={offers.find((offer)=>   carts.product===offer.id && carts.type==="offer" )}/>
              ))}
            
        </div>
         {mycart.length>0?(<div class="col-md-4" id="cartSummary">
        
        <div style={{backgroundColor:'#f4f4f4',minHeight:'400px',padding:'10px'}}>
          <h3>Cart Summary</h3>
          <hr/>

          <p style={{marginInline:'10px'}} >Product Price <span style={{float:'right'}}>₹{parseInt(productPrice)}</span></p>
          
          <p style={{marginInline:'10px'}} >Discount <span style={{float:'right'}}>₹0</span></p>
          <p style={{marginInline:'10px'}} >Shipping Charge {productPrice>1500 && (<Badge colorScheme='green'>Free Shipping</Badge>)} <span style={{float:'right'}}>₹{productPrice<1501?100:0}</span></p>
          <p style={{marginInline:'10px'}} >Round off <span style={{float:'right'}}>₹0.00</span></p>
          <p style={{marginInline:'10px'}} >Price before tax <span style={{float:'right'}}>₹{parseInt(productPrice-tax)}</span></p>
          <p style={{marginInline:'10px'}} >Tax values <span style={{float:'right'}}>₹{parseInt(tax)}</span></p>
          <hr/>
          <p style={{marginInline:'10px'}} >Total Price <span style={{float:'right',fontWeight:'bolder',color:'limegreen',fontSize:'30px'}}>₹{parseInt(productPrice+(productPrice>1500?0:100))}</span></p>

          <button ref={btnRef} onClick={()=>onOpen()} type='button' className='checkout__button'>Checkout</button>
        </div> 
      </div>) :''}

      {/* Drawer For Account    */}
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'md'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Complete Your Checkout</DrawerHeader>

          <DrawerBody>
          
             
            {localStorage.getItem('user')==null?
            <>
            <ul className='auth_options' style={{display:'flex',justifyContent:'center'}}>
              <li className={authPage=='login'?'active':''} onClick={()=>setAuthPage('login')}>Login </li>
              <li className={authPage=='signup'?'active':''}  onClick={()=>setAuthPage('signup')}>Signup</li>
              </ul> 
              {authPage=='login'?(<Login fromPage='cart'/>):<Register fromPage='cart' />}</>:<><br/><br/><br/><div className='text-center'>
              <h3>Instructions</h3>
              
              
   

   <div class="text-left">
   <ol className='instructions'>
              <li><strong>Initiate Payment:</strong> Click "Pay with Razorpay" to start the payment process.</li>
  <li><strong>Keep Window Open:</strong> Do not close the Razorpay payment window until the payment is complete.</li>
  <li><strong>Complete Payment:</strong> Follow instructions to enter payment details and complete the transaction.</li>
  <li><strong>Confirmation:</strong> Receive a confirmation message on Razorpay and via email after successful payment.</li>
  <li><strong>Return to Website:</strong> You'll be redirected back to our site with a confirmation message once payment is confirmed.</li>
</ol>
   </div>

   <Button  colorScheme='green'  onClick={()=>{  sendCreateOrder() } }>Pay With Razorpay</Button>
               
  <br/>
   
</div></>}
          </DrawerBody>

          
        </DrawerContent>
      </Drawer>

      </div>
        
        
          
          
          
       </>  )} 
       </div>

       
      <Footer/>
    </div>
  )
}

export default MyCart
