import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

function Fssai() {
  return (
    <div className='App'>
    <Header/>

    <div className='main__section'>
      <div className='text-center'>
         <img style={{margin:'auto'}} src='https://i.ibb.co/nzvjXSs/ekana-FSSAI.jpg'/> 
      </div>
      
      </div> 
    <Footer/>  
  </div>
     
  )
}

export default Fssai
