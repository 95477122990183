import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const OfferItem = ({ product }) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const handleRemoveFromCart = (productId) => {
  // Filter out the item to be removed based on its productId
  const updatedCart = cartItems.filter(item => item.product !== productId);
  
  // Update the local storage and state
  localStorage.setItem('cart', JSON.stringify(updatedCart));
  setCartItems(updatedCart);
  setAddedToCart(false); // Assuming you want to reset the "added to cart" state when an item is removed
};
   

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
    const found = cartItems.find(item => item.product === product.id);
    if (found) {
      setAddedToCart(true);
    }
  }, [cartItems, product]);

  const handleAddToCart = () => {
    const updatedCart = [...cartItems,{'quantity':1,'product':product.id,'type':'offer'} ];
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    setCartItems(updatedCart);
    setAddedToCart(true);
  };

  return (
    <div className='product__container'>
      <Link to={`/product/${product.product_slug}`}>
        <img className='product__image' src={`https://portal.ekanaherbs.com/custom/images/products/${product.offer_image}`} alt="Product Image" />
        <p className='product__brand'>EkanaHerbs Offers</p>
        <p className='product__title'>{product.offer_name}</p>
        <p className='product__quantity'>1 Qty</p>
        <p className='product__code h-font'>{product.available_offers}</p>
        </Link>
        <br />
        <p className='product__title'>₹ {product.offer_price} /-  
          {!addedToCart ? (
            <span className='product__addToCart' onClick={handleAddToCart}>
              <span className='product__addToCart__text'>Add to Cart</span>
            </span>
          ) : (
            <Link to="/my-cart">
              <span className='product__goToCart'>
                <span className='product__goToCart__text'>Go to Cart</span>
              </span>
            </Link>
          )}
        </p>
        <p className='product__brand'>Inclusive of all tax</p>
     
     
    </div>
  );
};

export default OfferItem;
