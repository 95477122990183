import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function useHistories() {
  const location = useLocation();
  const previousPath = useRef(location.pathname);

  useEffect(() => {
    previousPath.current = location.pathname;
  }, [location.pathname]);

  return previousPath.current;
}

export default useHistories;