import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

function BrandsPage() {
  return (
    <div className="App">
    <Header/>
    <div className='main__section'>
        <h1>Brands Page</h1> 
    </div>
  
    <Footer/>


</div>
  )
}

export default BrandsPage
