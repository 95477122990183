import React, { useEffect, useRef, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import axios from 'axios';
import ProductItem from '../Body/ProductItem';
import { useSearchParams } from 'react-router-dom';
import { Checkbox, Skeleton } from '@chakra-ui/react';
import { Stack } from 'react-bootstrap';
import bgHeader from './../images/products-1.jpeg'
 
function ProductsPage() {
  const divRef = useRef(null);
  const [width,setWidth]=useState(0);
  const [height,setHeight]=useState(0);
  const getDimensions = () => {
    const width = divRef.current.clientWidth;
    const height = divRef.current.clientHeight;
    setHeight(height);
    setWidth(width);
    console.log("Width:", width);
    console.log("Height:", height);
  };
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading]=useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleCheckboxChange = (selectedCategory) => {
    window.location.href=`/products/?category=${selectedCategory}`
    // Add any other logic you need here
  };
  // Get a specific query parameter
  const queryParam= searchParams.get('search')
  const categoryParam = searchParams.get('category')??'All'
  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.ekanaherbs.com/api/v1/categories');

      const responseOfProducts = await axios.get('https://api.ekanaherbs.com/api/v1/all-products');
      setProducts(responseOfProducts.data); 
      setCategories(response.data);
      setLoading(false)
      // Assuming the data is an array
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [searchTerm, setSearchTerm] = useState(queryParam);
   console.log(searchParams);
  const handleSubmit = (e) => {
    e.preventDefault();
    if(searchTerm==''){
      window.location.href = `/products`;
    }else{
      window.location.href = `/products?search=${searchTerm}`;
    }
    // Redirect to the products page with the search query
    
    
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className="App">
    <Header/>
    <div className='main__section'>
      <div className='bradcrumb'>Home / <strong>Products</strong></div>
      <div ref={divRef} className='smooth__header smooth__header__info  products-bg'   >
           {/* <h4 onClick={getDimensions}>Dimensions {width} X {height}</h4> */}

           <div className='search__container'>
           <form onSubmit={handleSubmit}>
      <input
        type="search"
        placeholder='Search for Products'
        value={searchTerm}
        onChange={handleInputChange}
      />
       
    </form>
           </div>

            
      </div>
       
      <div className='products_page'>
             <ul >
              <li>
                <ul>
                  <li>Filter(0)  <span className='clear__btn float__right'  >Clear all</span></li>
                  <li className='hfont' >All Category<span className='clear__btn float__right'  >
                    
                  <Checkbox size='lg' colorScheme='orange' defaultChecked={categoryParam === "All"} 
        onChange={() => handleCheckboxChange('All')}></Checkbox>
                  </span></li>
                  {categories.map((category) => (
  <li className='hfont' key={category.id}>
    {category.category_name} 
    <span className='clear__btn float__right'>
    <Checkbox  size='lg' colorScheme='orange'    defaultChecked={categoryParam === category.category_name} 
        onChange={() => handleCheckboxChange(category.category_name)}></Checkbox>
       
    </span>
  </li>
))}
 
                 
                </ul>
              </li >
               {loading==true?
               <li>
               <ul className='skeletal_ul'>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
               </ul>
                  

               </li>:<li>
                 
                 {products
     .filter((product) =>categoryParam==="All"|| product.category_name.toLowerCase().includes(categoryParam.toLowerCase())).length>0?(<>
                   <p style={{fontSize:'23px',fontWeight:600,margin:'10px 5px',}}>Products({products
     .filter((product) =>categoryParam==="All"|| product.category_name.toLowerCase().includes(categoryParam.toLowerCase())).length}) {categoryParam!==null?` in ${categoryParam}`:''}</p>
                   <ul id="product_list">
                   
                   { queryParam!==null ? (
     products
       .filter((product) => product.product_title.toLowerCase().includes(queryParam.toLowerCase()))
       .map((filteredProduct) => (
       <li>  <ProductItem key={filteredProduct.id} product={filteredProduct} />
      </li> ))
   )  :  (
     products
     .filter((product) =>categoryParam==="All"|| product.category_name.toLowerCase().includes(categoryParam.toLowerCase()))
     .map((product) => (
     <li> <ProductItem key={product.id} product={product} />
     </li> ))
   )}
                   </ul>
                   </>):(<h1>Products Coming Soon</h1>)}
                 </li>}
             </ul>
           </div>

    </div>
  
    <Footer/>


</div>
  )
}

export default ProductsPage
