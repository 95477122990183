import Axios from 'axios';

const axios = Axios.create({
	baseURL: "https://api.ekanaherbs.com/api/",
	 
	headers: {
		"Content-Type": "application/json",
		"Accept": "application/json", 
	},
});

export default axios;