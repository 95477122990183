import React from 'react'
import HeroProducts from './HeroProducts'
import TopSellingProducts from './TopSellingProducts'
import { redirect } from 'react-router'

const TopSelling = (props) => {
  return (
    <div>
      <h1>Top Selling</h1>
      <TopSellingProducts redirect={props.redirect}/>
    </div>
  )
}

export default TopSelling
