import React from 'react'
import MainHeader from './MainHeader'
import TopHeader from './TopHeader'
import ScrollToTop from '../Products/ScrollToTop'

function Header(props) {
  const currentPath = window.location.pathname;
  return (
    <div className='hdr'>
       <TopHeader isAuthenticated={props.isAuthenticated} pathname={currentPath} />
       <MainHeader isAuthenticated={props.isAuthenticated}  />
    </div>
  )
}

export default Header
