import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { useSearchParams } from 'react-router-dom';
 
import OfferItem from '../Body/OfferItem';
import axios from '../auth/defaultr-axios';
import { Skeleton } from '@chakra-ui/react';

import bgHeader from './../images/offers-header.jpg';
 

function OffersPage() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading]=useState(true);

  // Get a specific query parameter
  const queryParam= searchParams.get('search')
  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.ekanaherbs.com/api/v1/offer-categories');

      const responseOfProducts = await axios.get('https://api.ekanaherbs.com/api/v1/all-offers');
      setProducts(responseOfProducts.data); 
      setCategories(response.data);
      // Assuming the data is an array
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [searchTerm, setSearchTerm] = useState(queryParam);
   console.log(searchParams);
  const handleSubmit = (e) => {
    e.preventDefault();
    if(searchTerm==''){
      window.location.href = `/offers`;
    }else{
      window.location.href = `/offers?search=${searchTerm}`;
    }
    // Redirect to the products page with the search query
    
    
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className="App">
    <Header/>
    <div className='main__section'>
      <div className='bradcrumb'>Home / <strong>Offers</strong></div>
      <div className='smooth__header smooth__header__info offers'   >
           <h4>Offers</h4>
            

            
      </div>

      <div className='products_page'>
             <ul >
              <li>
                <ul>
                  <li>Filter(0)  <span className='clear__btn float__right'  >Clear all</span></li>
                  <li className='hfont' >All Category<span className='clear__btn float__right'  ><input type="checkbox"/></span></li>
                  {categories.map((category) => (
        
        <li className='hfont' key={category.id}>{category.name} <span className='clear__btn float__right'  ><input type="checkbox"/></span></li>
                  ))}
                 
                </ul>
              </li >
              {loading==true?
               <li>
               <ul className='skeletal_ul'>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
                  <li><Skeleton height='400px' width="inherit" /></li>
               </ul>
                  

               </li>:
              <li  >
                <p style={{fontSize:'23px',fontWeight:600,margin:'10px 5px',}}>Offers({products.length}) 
                 </p>
                <ul>
                
                { queryParam!==null ? (
  products
    .filter((product) => product.offer_title.toLowerCase().includes(queryParam.toLowerCase()))
    .map((filteredProduct) => (
    <li>  <OfferItem key={filteredProduct.id} product={filteredProduct} />
   </li> ))
) : (
  products.map((product) => (
  <li> <OfferItem key={product.id} product={product} />
  </li> ))
)}
                </ul>
              </li>}
             </ul>
           </div>

    </div>
  
    <Footer/>


</div>
  )
}

export default OffersPage
