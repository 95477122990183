import React from 'react'
import family from '../../images/family.jpg'
import ImageHeader from '../../components/ImageHeader'
import earn from '../../images/earn-grow.jpg';
import happiness from '../../images/live-happier.jpg';
import markplan from '../../images/mark-plan.jpg';



function AboutComponent() {
  return (
    <div>
      <img src={family} style={{'width':'100%'}} />
      <div className='shaded-bg'>
        <h4>About Our Company</h4>
      </div>
      <div className='content__bg'>
      <p>
    At Ekana Herbs Private Limited, we are passionate about harnessing the power of nature to promote holistic well-being and optimal health. As a herbal products-based company, we are dedicated to providing our customers with the finest quality herbal remedies and products that nurture the body, mind, and soul.
  </p>

  <p>
    Founded with a deep-rooted belief in the healing properties of herbs, Ekana Herbs Private Limited is committed to delivering natural solutions that enhance vitality and restore balance. We draw inspiration from ancient herbal traditions while integrating modern scientific advancements, ensuring that our products are effective, safe, and sustainable.
  </p>

  <p>
    Our journey began with a vision to revive and share the wisdom of herbal remedies that have been used for centuries. We meticulously select the finest botanical ingredients from around the world, handpicking herbs renowned for their therapeutic benefits. Every product is carefully crafted to preserve the integrity and potency of these natural treasures.
  </p>

  <p>
    We take pride in our commitment to quality and transparency. Our manufacturing processes adhere to stringent standards, ensuring that each batch of our herbal products is pure, potent, and free from harmful additives. We prioritize sustainability and ethical sourcing, working closely with trusted growers and suppliers who share our values.
  </p>
      </div>
 
      <ImageHeader bgColor='#ccefcb' color="#077004" text="Earn & Grow" image={earn}/>
      <div className='content__bg'>
      <p>
    At Ekana Herbs Private Limited, we believe in the power of collaboration and providing opportunities for individuals to not only improve their well-being but also to earn and grow with us. As a herbal products-based company, we are dedicated to creating a supportive environment where our customers can not only benefit from our high-quality products but also participate in a rewarding business opportunity.
  </p>

  <p>
    When you join Ekana Herbs Private Limited, you become part of a dynamic community that values personal growth, financial freedom, and holistic wellness. We offer a unique business model that allows individuals to become independent distributors of our herbal products, empowering them to build their own successful ventures while promoting the benefits of natural health.
  </p>

  <p>
    Our distributors have the opportunity to earn income through retail sales, team building, and leadership development. By sharing our products with others and building a network of like-minded individuals, you can create a sustainable source of income that aligns with your passion for herbal remedies and wellness.
  </p>

  <p>
    As an Ekana Herbs Private Limited distributor, you'll receive comprehensive training, marketing resources, and ongoing support from our experienced team. We are committed to equipping you with the knowledge and tools needed to thrive in your business, whether you're new to entrepreneurship or an experienced professional.
  </p>

  <p>
    But it's not just about financial gains. We believe in fostering personal growth and empowerment. Through our training programs and community events, you'll have the opportunity to expand your skills, enhance your leadership abilities, and cultivate meaningful relationships with fellow distributors who share your vision.
  </p>
      </div>
      <ImageHeader bgColor='#deddf1' color="#3e0982" text="Live Happier" image={happiness}/>
      <div className='content__bg'>
      <p>
    At Ekana Herbs Private Limited, we strive for a culture of collaboration, integrity, and abundance. We celebrate the success of our distributors and recognize their achievements through various incentive programs and rewards. We believe that by supporting each other, we can create a ripple effect of positive change in the lives of individuals and communities worldwide.
  </p>

  <p>
    Whether you're seeking a part-time opportunity to supplement your income or aiming to build a thriving business, Ekana Herbs Private Limited provides a flexible platform that adapts to your goals and aspirations. Our herbal products have a strong market demand, and we continue to innovate and expand our offerings to stay at the forefront of the industry.
  </p>

  <p>
    Come and embark on this exciting journey with us, where you can earn and grow while making a difference. Join the Ekana Herbs Private Limited family and experience the fulfillment that comes from helping others embrace the power of natural wellness. Together, we can achieve personal and financial prosperity while promoting a healthier, more vibrant world.
  </p>
      </div>
    </div>
  )
}

export default AboutComponent
