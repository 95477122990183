import React, { Fragment, useEffect, useState } from 'react'
import ProductImageCarousel from '../Pages/Products/ProductImageCarousel'
import ProductCarousel from '../Body/ProductsCarousel' 
import TopSelling from '../Body/TopSelling';
import DOMPurify from 'dompurify';
import { AccordionIcon, Alert, AlertIcon, useToast } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box } from "@chakra-ui/react";

import { Link } from 'react-router-dom';

function ProductDetails(props) {
  const toast= useToast();
  const [quantity,setQuantity]= useState(1);
  const [cartItems,setCartItems]= useState([]);
  const [addedToCart,setAddedToCart]=useState(false);
  const handleQuantityChange=(e)=>{
    if(parseInt(e.target.value)>0&& parseInt(e.target.value)<41){
      setQuantity(parseInt(e.target.value));
    }
    else if(e.target.value == ''){
      setQuantity('');
    }else{
      toast({
        title: 'Invalid Value Please enter quantity from 1 to 40. ', 
        status: 'warning',
        duration: 2000,
        position:'top',
        isClosable: true,
      })
    }
     
  }

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
    const found = cartItems.find(item => item.product === props.product.id);
    if (found) {
      setQuantity(cartItems.find(item => item.product === props.product.id).quantity)
      setAddedToCart(true);
    }
  }, [cartItems]);
  const handleAddToCart = (product) => {
    if(quantity==''){
      toast({
        title: 'Please enter valid quantity', 
        status: 'warning',
        duration: 2000,
        position:'top',
        isClosable: true,
      })
    
    }else{
      const updatedCart = [...cartItems,{'quantity':quantity,'product':product.id,'type':'product'} ];
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCartItems(updatedCart);
      setAddedToCart(true);
    }
     
  };
  const sanitizedHTML = DOMPurify.sanitize(props.product.product_details);
  return (
    
    <div className='product__detail'>
       <ul>
        <li>
        <ProductCarousel image={`https://portal.ekanaherbs.com/custom/images/products/new/${props.product.images.split(',')[1].replace('-','/')}`} />

        </li>
        <li> 
            <div className='product__details'>
             <p> <strong> {props.product.category_name}</strong>  </p>

             <h4  className='product__title_text'>{props.product.product_title}</h4>
             <p className='product__description_text'>{props.product.product_desc}</p>
              
             <h5 className='product__price_text2'>₹ {props.product.product_price}</h5>
             <p className='product__brand_text' >{ '{ Inclusive of all taxes }'}</p>
 
              <div style={{paddingBlock:'20px',maxWidth:'600px'}}>
                
              <Alert status='success'>
    <AlertIcon />
   <p>Register for Lifetime Free Membership to get upto 40% discount on purchase.  <span style={{fontWeight:'bold'}}><Link to="/start-a-bussines">Click here</Link></span>   for more details.</p>
              </Alert>
              </div>
             


             
             <div style={{borderTop:'1px dotted gray',marginBottom:'10px'}}></div>
             <ul style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                <li>
                    <input className='product_quantity__input'   value={quantity} onChange={(e)=>handleQuantityChange(e)}  typeof="number"  readOnly={addedToCart}/>
                </li>
                <li>{!addedToCart ? (
            <span className='product__addToCart' onClick={()=>handleAddToCart(props.product)}>
              <span className='product__addToCart__text'>Add to Cart</span>
            </span>
          ) : (
            <Link to="/my-cart">
              <span className='product__goToCart'>
                <span className='product__goToCart__text'>Go to Cart</span>
              </span>
            </Link>
          )}</li>
             </ul>

             <p>Size : {props.product.product_quantity} {props.product.size_type_id}</p> 

             <Accordion defaultIndex={[0]}  allowToggle>
      <AccordionItem>
        <h2>
          
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <h5>Product Details</h5>
            </Box>
            <AccordionIcon/>
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <div>
            {props.product.small_details.split('.').map((details, index) => (
              <p key={index}>{details}.</p>
            ))}
          </div>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
            <h5>Features</h5>
            </Box>
            <AccordionIcon/>
          </AccordionButton>
        </h2>
        <AccordionPanel>
          <div className="features" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
            <h5>Frequently Asked Questions </h5>
            </Box>
            <AccordionIcon/>
          </AccordionButton>
        </h2>
        <AccordionPanel>
          {props.product.product_faqs.split('<>').map((faq, index) => (
            <Fragment key={index}>
              <h5 style={{ color: "#946c24" }}>{faq}</h5>
              <p style={{ fontSize: "20px" }}>{props.product.product_faqs_answers.split('<>')[index]}</p>
              <br />
            </Fragment>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>

            </div>
        </li>
       </ul>
       <br/>
       <div style={{borderTop:'1px dotted gray'}}>

       </div>
       <TopSelling redirect={true}/>
    </div>
  )
}

export default ProductDetails
