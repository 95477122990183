import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ProductItem = ({ product }) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);

    const existingItemIndex = cart.findIndex(item => item.product === product.id);
    if (existingItemIndex !== -1) {
      setAddedToCart(true);
    }
  }, [product.id]);

  const addToCart = (newCartItem) => {
    const updatedCartItems = [...cartItems, newCartItem];
    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
    setAddedToCart(true);
  };

  const handleAddToCart = (productId) => {
    const newItem = { quantity: 1, product: productId, type: 'product' };
     const oldItems=JSON.parse(localStorage.getItem('cart'))??[];
    //addToCart(newItem);
    const finalItems=[...oldItems,newItem];
    localStorage.setItem('cart',JSON.stringify(finalItems))
    
    setAddedToCart(true);
  };


  return (
    <div className='product__container'>
      <a href={`/product/${product.product_slug}`}>
        <img className='' src={`https://portal.ekanaherbs.com/custom/images/products/new/${product.images.split(',')[0].replace('-','/')}`} alt="Product Image" />
        <p className='product__title'>{product.product_title}</p>
        <p className='product__quantity'>{product.product_quantity} {product.size_type_id}</p>
        <p className='product__code h-font'>{product.product_code}</p>
      </a>
      <br />
      <p className='product__title'>₹ {product.product_price} /-  
        {!addedToCart ? (
          <span className='product__addToCart' onClick={()=>handleAddToCart(product.id)}>
            <span className='product__addToCart__text'>Add to Cart</span>
          </span>
        ) : (
          <Link to="/my-cart">
            <span className='product__goToCart'>
              <span className='product__goToCart__text'>Go to Cart</span>
            </span>
          </Link>
        )}
      </p>
      <p className='product__brand'>Inclusive of all tax</p>
    </div>
  );
};

export default ProductItem;
