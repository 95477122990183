import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel'; 
import ProductImage from '../Pages/Products/ProductImage';
 

function ProductCarousel(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
      <ProductImage image={props.image} />
         
      </Carousel.Item>
      <Carousel.Item>
      <ProductImage image={props.image.replace('800','800desc')} />
         
      </Carousel.Item>
       
    </Carousel>
  );
}

export default ProductCarousel;