import React from 'react'
import Login from '../Login'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

function LoginPage() {
  return (
    <div className='App' >
        <Header/>
     <div className='main__section' style={{maxWidth:'500px',margin:'auto',paddingInline:'20px'}}>
        <Login isLoginPage={true}/>   
        </div>  
       <Footer/>
    </div>
  
  )
}

export default LoginPage
