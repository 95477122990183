import React from 'react'
import happines from '../../src/images/happines.jpg'
import earn from '../../src/images/earn.jpg'


function ImageHeader(props) {
  return (
    <div  className='image__header'>
      <ul>
           <li>
           <img src={props.image}/>
           </li>
           <li style={{'backgroundColor':props.bgColor,'color':props.color}}>
            {props.text}
           </li>
      </ul>
    </div>
  )
}

export default ImageHeader
