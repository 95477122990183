import axios from 'axios';
import { createContext, useContext, useState } from 'react';
 

 
 

const AuthContent = createContext({
	user: null,
	keyValue:'1222111',
	setUser: () => {}, 
});

export const AuthProvider = ({ children }) => {
	console.log("aa")
	const [user, _setUser] = useState(
		JSON.parse(localStorage.getItem('user')) || null
	);

	// set user to local storage
	const setUser = (userR) => {
		console.log("aaaaa")
		// if (userR) {
		// 	localStorage.setItem('user', JSON.stringify(userR));
		// 	console.warn("Setting user")
		// } else {
		// 	console.warn("No user Found")
		// 	localStorage.removeItem('user');
		// } 
		_setUser("userR")
	};
	const keyValue="12345111";
   
	const accc=()=>{
		setUser("jjjj")
	}
 
	 

	return (
		<AuthContent.Provider value={{ user, setUser,keyValue}}>
			{children}
		</AuthContent.Provider>
	);
};

export const useAuthContext = () => {
	return useContext(AuthContent);
};