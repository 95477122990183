import React from 'react'
import { Link } from 'react-router-dom'

const ProductBanner = (props) => {
  return (
    <div className='product__banner'>
        <img src={props.image}/>
        <div className='text_container'>
            <h3>{props.text}</h3>
            <Link to={props.product_link}>
            <button className='banner_explore_button'>Explore</button>
            </Link>
            
        </div>
      
    </div>
  )
}

export default ProductBanner
