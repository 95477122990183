import React from 'react'
import { Link } from 'react-router-dom'
import FaceBookIcon from '../svgs/FaceBookIcon'
import Instagram from '../svgs/Instagram'
import Youtube from '../svgs/Youtube'
import Twitter from '../svgs/Twitter'
import TeleGram from '../svgs/TeleGram'

function Footer() {
  return (
    <>
    <br/>
    <br/>
    <div className='footer'>
     <ul className='main_footer_ul'>

     <li style={{textAlign:'center'}}>
      <img src='https://portal.ekanaherbs.com/custom/images/EkanaLogo.png' style={{margin:'auto',height:'100px'}} />
     
     
        <h5 style={{"fontSize":"16px",lineHeight:'20px',textAlign:'left',"marginTop":'6px',textAlign:'center'}}>
        Ekana Herbs Pvt. Ltd.
        <br/>
         Lower Ground Floor Block T-4, Indraprasth Apartment Ratanlal Nagar Kanpur , U.P. 208022
<br/>
<p className='text-center'>
Email ID - support@ekanaherbs.com<br/>
Tel:  0512-4059723 
</p>

 
<div className='social_icons text-center'>
       <a  target='_blank'  href='https://www.facebook.com/profile.php?id=100091429549546&mibextid=ZbWKwL'><FaceBookIcon  color="black"/></a>
        <a target='_blank'   href='https://instagram.com/ekanaherbs_official?igshid=ZDdkNTZiNTM='> <Instagram color='black'/> </a>
        <a target='_blank' href='https://www.youtube.com/@EkanaHerbs'><Youtube color='black'/></a>
        </div>
        </h5>
      </li>

      <li>
        <p>Important Links</p>
        <ul>
          <li> <Link to="/privacy-policy" >Privacy Policy</Link> </li>
          <li> <Link to="/terms-of-use" >Terms of Use</Link></li>
          <li> <Link to="/refund-policy" >Refund Policy</Link></li>
          <li> <Link to="/fssai" >FSSAI</Link></li>
          <li> <Link to="/iso" >ISO</Link></li>
          <li> <Link to="/contact-us" >Contact Us</Link></li>
        </ul>
      </li>
      <li>
        <p>Quick Links</p>
        <ul>
          <li> <Link to="/faq" >FAQs</Link> </li>
          <li> <Link to="/ekana-academy" >Ekana Academy</Link></li>
          <li> <Link to="/about-ekana/start-a-bussines" >Start a Business</Link></li>
          <li> <Link to="/shipping-process" >Shipping & Pickup Procedures</Link></li>
        </ul><li>
      </li></li>

     
     

      
      
      </ul>
      <hr/>
      <p style={{textAlign:'center',fontSize:'12px'}}>Copyright: 2024 Ekana Herbs Private Limited  </p>
    </div>
    </>
    
  )
}

export default Footer 