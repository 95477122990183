import React from 'react' 
import { useState,useEffect } from 'react';
import ProductItem from './ProductItem'; 
import axios from '../auth/defaultr-axios';

const TopSellingProducts = (props) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
      // Fetch data when the component mounts
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.ekanaherbs.com/api/v1/hero-products');
        setProducts(response.data); // Assuming the data is an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    return (
      <div className='products' > 
        <ul>
           
          {products.map((product) => (
            <li  key={"a"+product.id}>
               
               <ProductItem redirect={props.redirect} product={product}/>
               
               </li>
        
          ))}
        </ul>
      </div>
    )
}

export default TopSellingProducts
