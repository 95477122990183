
import React, { useEffect, useState } from 'react';
import { HStack, IconButton, Skeleton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from '@chakra-ui/icons';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { useToast } from '@chakra-ui/react' 
import axios from 'axios';
import Youtube from '../svgs/Youtube';
import { useSearchParams } from 'react-router-dom';
import bgHeader from './../images/ekn-academy.jpg';


function EkanaAcademy() {
    const toast = useToast();
      const videoLinks=['lZEHVuisGzM','Pipa9Fec97Y','yKxkIUzCPU','lZEHVuisGzM','Pipa9Fec97Y','lZEHVuisGzM','Pipa9Fec97Y','yKxkIUzCPU','lZEHVuisGzM','Pipa9Fec97Y','yKxkIUzCPU','lZEHVuisGzM','Pipa9Fec97Y','yKxkIUzCPU','lZEHVuisGzM','Pipa9Fec97Y','yKxkIUzCPU'];

    // Set how many videos to display per page
    const videosPerPage = 6;
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParam= searchParams.get('type')??0; 
    const [currentPage, setCurrentPage] = useState(1);
    const [cats,setCats]=useState([]);
    const [resources,setResources] = useState([])
    const [loading, setLoading]= useState(true);

    // Calculate the indexes of videos to display for the current page
    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = videoLinks.slice(indexOfFirstVideo, indexOfLastVideo);
    
    const handleFilter = (e) => {
        if (e.target.checked) {
          window.location.href = '/ekana-academy?type=' + e.target.value;
        }
    };
    // Handle page navigation
    const nextPage = () => {
        
             
           
          setCurrentPage((prevPage) => prevPage + 1);  
        
    };
    const fetchData = async () => {
        try {
          const response = await axios.get('https://api.ekanaherbs.com/api/v1/academy-category');
    
          const responseOfProducts = await axios.get('https://api.ekanaherbs.com/api/v1/all-academy-files');
          setResources(responseOfProducts.data); 
          setCats(response.data);
          setLoading(false)
          // Assuming the data is an array
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    const prevPage = () => {
        
         setCurrentPage((prevPage) => prevPage - 1);   
        
        
    };
    useEffect(()=>{
    fetchData()
    },[])

    return (
        <div className="App">
            <Header/>
            <div className='main__section'>
            <div className='smooth__header smooth__header__info academy' >
                <h4>Ekana Academy</h4>   
            </div>
        <div className='products_page'>
         <ul> 
            <li>
            <ul>
                        <li>Filter <span style={{float:'right'}}>(0)</span>
                        </li>
                        <li>
                            All Sources <input type="checkbox" onChange={(e)=>handleFilter(e)} checked={queryParam==0} value='0'  />
                        </li>
                        {cats.map((cat,kye)=><li key={kye}>{cat.name} <input onChange={(e)=>handleFilter(e)} value={cat.id} type="checkbox" checked={queryParam==cat.id}/></li>)}
                        
                        
                    </ul>
            </li>
            {loading==true?
               <li>
               <ul className='skeletal_ul'>
                  <li><Skeleton height='300px' width="inherit" /></li>
                  <li><Skeleton height='300px' width="inherit" /></li>
                  <li><Skeleton height='300px' width="inherit" /></li>
                  <li><Skeleton height='300px' width="inherit" /></li>
                  <li><Skeleton height='300px' width="inherit" /></li>
                  <li><Skeleton height='300px' width="inherit" /></li>
               </ul>
                  

               </li>:
            <li>
            <div className="page__content">
                <div className="item__container">
                    
                    <ul className='resource_ul'>
                        {resources.filter((res)=>res.academy_type==queryParam|| queryParam==0).map((video, index) => (
                            <li key={index}>
                               
                               <img src={'https://portal.ekanaherbs.com/custom/images/products/'+video.thumbnail}  alt="pdf_image"/>
                               <p>
                                {video.title}
                                 <a href={video.link} target="_blank" style={{float:'right'}}>
                                 {  video.link.includes('youtube')?<Youtube color="white"/>:  <DownloadIcon/>}</a> 
                               </p>
                                
                               
                            </li>
                        ))}
                    </ul>
                </div>
                {resources.filter((res)=>res.resource_type==queryParam|| queryParam==0).length>0?
                '':''}
                {resources.filter((res)=>res.resource_type==queryParam|| queryParam==0).length<1?<h3>No File Found</h3>:''}
            </div>
            </li>}
        </ul>   
        </div>
          
          </div>

          <Footer/>
        
        </div>
    );
}

export default EkanaAcademy;

