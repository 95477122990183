import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Error404 from '../../Products/Error404'
import UnderDevelopment from '../../Products/UnderDevelopment'

function RefundPolicy() {
  return (
    <div className='App'>
      <Header/>
      <div className='main__section container policy-box' style={{textAlign:'left'}}>
        <h1 style={{textAlign:'center'}}>Refund Policy</h1>
        <hr/>
        <h2>1. General Policy:</h2>
<p>We at Ekana Herbs Private Limited strive to provide our customers with high-quality products and exceptional service. However, there may be instances where a refund or replacement is requested. Please read the following refund policy carefully before making a purchase.</p>

<h2>2. No Refund/Replacement for Customer:</h2>
<ol>
  <li>All sales are final, and we do not offer refunds or replacements for any products purchased through our platform unless explicitly stated otherwise due to applicable laws or regulations.</li>
  <li>We encourage customers to thoroughly review product details, specifications, and uses before making a purchase.</li>
  <li>In the event of dissatisfaction with a product, we may, at our discretion, offer assistance, troubleshooting guidance, or other forms of support, but this does not entail a refund or replacement.</li>
</ol>

<h2>3. Ekana Business Owner (EBO) Policy:</h2>
<ol>
  <li>Ebana Business Owner (EBOs) are eligible for product replacements within the same business month before closing, subject to specific terms and conditions.</li>
  <li>EBOs must submit a claim for product replacement within the same business month of purchase.</li>
  <li>Product replacements are subject to a handling charge of 10% of the product's purchase price, as well as logistics charges. These charges will be borne by the EBO.</li>
  <li>To initiate a replacement claim, the EBO must provide valid proof of purchase and demonstrate the need for replacement due to product defects or damages incurred during transit.</li>
  <li>Replacement products will be provided based on availability and at the discretion of Ekana Herbs Private Limited. If the exact product is not available, a similar product or store credit (PV) may be offered.</li>
  <li>Replacement claims will not be entertained for products damaged due to mishandling, misuse, or neglect on the part of the EBO.</li>
</ol>

<h2>4. Exceptions:</h2>
<ol>
  <li>In case of any dispute or exceptional circumstances, Ekana Herbs Private Limited reserves the right to review and reconsider refund or replacement requests on a case-by-case basis.</li>
  <li>Any exceptions to this policy will be at the sole discretion of Ekana Herbs Private Limited and may require additional documentation or evidence to support the claim.</li>
</ol>

<h2>5. Contact Us:</h2>
<p>If you have any questions or concerns regarding our refund policy, please feel free to contact our customer service team at <a href="mailto:support@ekanaherbs.com">support@ekanaherbs.com</a>.</p>

<p><strong>Note:</strong> This refund policy is subject to change without prior notice. Please review this document periodically for any updates or amendments.</p>

      </div>
      <Footer/>
    </div>
  )
}

export default RefundPolicy
