import { Button, FormLabel, Input, Textarea } from '@chakra-ui/react'
import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Youtube from '../svgs/Youtube'
import Instagram from '../svgs/Instagram'
import FaceBookIcon from '../svgs/FaceBookIcon'

function Contact() {
  return (
    <div className="App">
    <Header/>
    <div style={{"paddingTop":'110px'}}></div>
    <div className='smooth__header smooth__header__info  '>
           <h4>Contact Us</h4>
           <div className='search__container'>
            
           </div>

            
      </div> 
    <div className='container'>
       <div className='row d-flex'>
      <div className='col-md-6'>
         <div className='card py-5  text-center'>
           <span className='avatar'><img src='https://admin.ekanaherbs.com/custom/images/EkanaLogo.png' style={{height:'170px'}} className='m-auto' /></span>
             <h2>
            You Can Contact Us Directly At
             </h2> 
            <h3>support@ekanaherbs.com</h3>
            <div className='social_icons text-center'>
       <a  target='_blank'  href='https://www.facebook.com/profile.php?id=100091429549546&mibextid=ZbWKwL'><FaceBookIcon   color="black"/></a>
        <a target='_blank'   href='https://instagram.com/ekanaherbs_official?igshid=ZDdkNTZiNTM='> <Instagram color='black'/> </a>
        <a target='_blank' href='https://www.youtube.com/@EkanaHerbs'><Youtube color='black'/></a>
        </div>
         </div>
      </div>
      <div className='col-md-6'>
        <h1 style={{textAlign:'left'}}>Fill this form to reach out to us</h1>
        <br/>
        <div className='py-3 px-2'>
        <FormLabel>Email</FormLabel>
         <Input type='email' />

         <FormLabel>Name</FormLabel>
         <Input type='text'/>
         <FormLabel>Phone</FormLabel>
         <Input type='text'/>

         <FormLabel>Message</FormLabel>
         <Textarea/>
        
        </div>
         
         <br/>
         <br/>
         <Button colorScheme='orange'>Send Message</Button>
      </div>
        </div> 
    </div>
    <Footer/>
     </div>
     

    
  )
}

export default Contact
