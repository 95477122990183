import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'

function UserAgreement() {
  return (
    <div className='App'>
      <Header/>
      <div className='text-center main__section'>
        <h1>UserAgreement</h1>
      </div>
      <Footer/>
    </div>
  )
}

export default UserAgreement
