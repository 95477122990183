import React from 'react' 
import { useState,useEffect } from 'react';
import ProductItem from './ProductItem';
import axios from '../auth/defaultr-axios';

const HeroProducts = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
      // Fetch data when the component mounts
      fetchData();
    }, []);
  
    const fetchData = async () => {
      try {
        const response = await axios.get('v1/hero-products');
        setProducts(response.data); // Assuming the data is an array
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    return (
      <div className='products' >
        <h2>Products For You</h2>
        <ul>
          {products.map((product) => (
            <li  key={product.id}>
               <ProductItem product={product}/>
               </li>
          ))}
        </ul>
      </div>
    )
}

export default HeroProducts
