import React from 'react'

function ProductImage(props) {
  return (
    <div style={{ height: '500px', backgroundColor: '#fefefe', color: 'white', backgroundImage: `url(${props.image})`, backgroundSize:'100% 100%',backgroundPosition:'center' }}>
      
</div>

  )
}

export default ProductImage
