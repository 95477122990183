import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input, 
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,

} from '@chakra-ui/react'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
import useHistories from './auth/useHistory';
import { useHistory } from 'react-router-dom';



const Register = (props) => { 
  const previousPath = useHistories();
  const [message,setMessage]=useState('');
  const [isRegistered,setIsRegistered]=useState(false);
  const [fullName, setFullName] = useState('');
  const [btnLoading,setBtnLoading]= useState(false);
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [policyAgreed,setPolicyAgreed]=useState(true);

  const toast = useToast();

  const handlePolicyAgreement=()=>{
    setPolicyAgreed(!policyAgreed);
  }
  const handleRegister =async () => {
    // Validate Full Name
    
    if (!fullName) {
      showToast('Validation Error', 'Please enter your full name.', 'error');
      return;
    }

    // Validate Email
    if (!email || !isValidEmail(email)) {
      showToast('Validation Error', 'Please enter a valid email address.', 'error');
      return;
    }

    // Validate Mobile Number
    if (!mobileNumber || !isValidMobileNumber(mobileNumber)) {
      showToast('Validation Error', 'Please enter a valid mobile number.', 'error');
      return;
    }

    // Validate Password
    if (!password || password.length < 6) {
      showToast('Validation Error', 'Password should be at least 6 characters.', 'error');
      return;
    }

    // Validate Confirm Password
    if (password !== confirmPassword) {
      showToast('Validation Error', 'Passwords do not match.', 'error');
      return;
    }

    const body = {
        email: email,
        password: password,
        'name':fullName,
        'mobile':mobileNumber
    }; 
    setBtnLoading(true)
    try {
        const resp = await axios.post('https://api.ekanaherbs.com/api/v1/register', body,  {
            "Content-Type": "application/json",
            "Accept": "application/json", 
          },);
        if (resp.data.scode === true) {
    setBtnLoading(false);
    setMessage(resp.data.message);
    setIsRegistered(true);
    showToast('Registration Successful', 'You have successfully registered!', 'success');
    localStorage.setItem('token',resp.data.token)
    //check previous route was my cart
    window.location.href=previousPath;
     
        }else{
    setBtnLoading(false)
  console.warn(resp.data)
  showToast('Registration Failed',resp.data.message,'error')
  }
    } catch (error) {
   
         
      setBtnLoading(false)
        showToast('Registration Failed',error.message,'error')
         
    }

    // Show success toast
    
  };

  const showToast = (title, description, status) => {
    toast({
      title,
      description,
      status,
      duration: 5000,
      position:'top',
      isClosable: true,
    });
  };

  const isValidEmail = (email) => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber) => {
    // Basic mobile number validation (10 digits)
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  };

  return (
    isRegistered?<><Alert
    status='success'
    style={{marginTop:'100px',}}
    variant='subtle'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    textAlign='center'
    height='400px'
  >
    <AlertIcon boxSize='40px' mr={0} />
    <AlertTitle mt={4} mb={1} fontSize='lg'>
      Registration Successfull!
    </AlertTitle>
    <AlertDescription maxWidth='md'>
      Dear customer, welcome to Ekana Family .
      Thanks for registeration please save your login id and password for future refrence.<br></br> 
      {message} .
      <br/>
      <Button colorScheme='green'> <Link to="/login" >Login</Link> </Button>
    </AlertDescription>
  </Alert></>: <>
  <h2 style={{marginTop:'10px'}}>Please create an account to continue. </h2>
  <br/>
      {/* Your form inputs */}
      <FormControl>
        <FormLabel>Enter Full Name</FormLabel>
        <Input
          type='text'
          placeholder='Please Enter your full name'
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          type='email'
          placeholder='Please Enter your email id'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Enter Mobile Number</FormLabel>
        <Input
          type='number'
          placeholder='Please Enter your mobile number'
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Password</FormLabel>
        <Input
          type='password'
          placeholder='Please Enter your password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Confirm Password</FormLabel>
        <Input
          type='password'
          placeholder='Please Confirm password'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </FormControl>

      {/* Your other form elements */}
      <br/>
            {props.fromPage==null?(<><p className='text-muted d-flex justify-content-end'>Already Have Account <Link to="/login"> <span style={{color:'indigo',marginInline:'5px',fontWeight:'bolder'}}> Login Here</span></Link>  </p></>):''}   
               <br/>
      
      {/* Your agreement checkbox and register button */}
      <div className='text-center'>
        <div style={{ marginBlock: '10px' }}>
          <Checkbox isChecked={policyAgreed}  onChange={(e) => handlePolicyAgreement()} >
            I agree <Link to="/privacy-policy">Ekana's privacy policy</Link>
          </Checkbox>
        </div>
       
        <Button colorScheme='orange' isLoading={btnLoading} isDisabled={(btnLoading||!policyAgreed)} loadingText={<>Please Wait</>} onClick={()=>handleRegister()} >Register</Button>
      </div>
    </>
  );
};

export default Register;
