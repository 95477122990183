import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

function Iso() {
  return (
    <div className='App'>
      <Header/>
  
      <div className='main__section'>
        <div className='text-center'>
           <img style={{margin:'auto'}} src='https://i.ibb.co/1KhgM8j/ekanaISO.jpg'/> 
        </div>
        
        </div> 
      <Footer/>  
    </div>
  )
}

export default  Iso
