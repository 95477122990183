import React from 'react'

function CTA() {
  return (
    <div className='cta'>
    
      <div className='cta__button'>Join Us</div>
    </div>
  )
}

export default CTA
