import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom' 

import { Button, Card, CardBody, FormControl, FormHelperText, FormLabel, Input, Spinner, Text, Toast, useDisclosure, useToast } from '@chakra-ui/react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import axios from 'axios'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { useAuthContext } from '../auth/Auth'


function TopHeader(props) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const signin= useAuthContext();
  const [openDialog, setOpenDialog] = useState(false);
  
  const cancelRef = React.useRef()
  const [pincode, setPincode]= useState(0)
  const [loading,setLoading]  = useState(false)
  const  [offices,setOffices]= useState([])
  const [selectedOffice, setSelectedOffice] = useState(null);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handlePincodeSet = (officeId,name ) => {
    // Retrieve the office object based on its ID
    const selectedOffice = offices.find(office => office.Pincode === officeId && office.Name=== name);
    if (selectedOffice) {
      localStorage.setItem('selectedOffice', JSON.stringify(selectedOffice));
      onClose();
      setOffices([]);
      setOpenDialog(false);
      setLoading(false)
      setSelectedOffice(selectedOffice)
      // Set 'selectedOffice' in localStorage with the selected office object
      
    }
  };
 

  

  const handlePinCodeInput = (e)=>{
    setOffices([])
    if(e.target.value.length!==6){
   setLoading(false)
    }else{
      
      setLoading(true)
      
      fetchData(e.target.value);

    }
     setPincode(e.target.value);
  }
  const fetchData = async (value) => {
    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${value}`)
   
      if(response.data[0].Status==='Error'){
        setLoading(false)
      }else if(response.data[0].Status==='Success'){
        console.log(response.data[0]);
        setOffices(response.data[0].PostOffice)
      }else{
        alert("Failed to Hit Api")
        setLoading(false)
      }
    } catch (error) {
      toast({
        title: 'Api Error',
        description: `${error}`,
      
        status: 'warning',
        duration: 9000,
        
        isClosable: true,
        position:'top'
      })
      console.error('Error fetching data:', error);
      //setLoading(false)
    }
  };
  useEffect(()=>{
    const selOffice = JSON.parse(localStorage.getItem('selectedOffice')) || [];
    setSelectedOffice(selOffice)
    setOffices([])
     
      if (props.pathname === '/my-cart' && selOffice.Name==null){
        setOpenDialog(true);
      }
     
  },[])
  return (
    <div className='header__top'>
      
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={()=>setOpenDialog(false)}
        isOpen={openDialog}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Setup Delivery Location?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
          { (selectedOffice!==''&& selectedOffice !==null)?<div >
  <p>Current Delivery Location</p>
  <p style={{fontWeight:'bolder'}}>{selectedOffice.Name} {selectedOffice.Name==null?'':','}{selectedOffice.State} {selectedOffice.State==null?'':'-'}{selectedOffice.Pincode}  </p><hr/>
</div>:''}

          <FormControl>
  <FormLabel>Enter Pincode</FormLabel>
  <Input type='number' onChange={handlePinCodeInput} />
  <FormHelperText>Please Enter your area 6 digit pincode.</FormHelperText>
</FormControl> 
 
{offices.length<1?<div>
{ loading?<div className='text-center p-5'>
  <Spinner/>
  <p className='text-center text-muted'>Getting Location Please Wait</p>
</div>:''}
 
{ !loading?<div className='text-center p-5'>
  <img src='https://cdn-icons-png.flaticon.com/256/11105/11105996.png' style={{height:'100px'}} className='m-auto' />
  <p className='text-center text-muted'>No Location Found</p>
</div>:''}
</div>:<ul>
  {offices.map((office,key) => <li key={key}><Card>
  <CardBody>
    <Text>
       <p>{office.Name} ,{office.State} <span style={{float:'right'}} onClick={()=>handlePincodeSet(office.Pincode, office.Name)}><ArrowRightIcon /></span></p>
       
    </Text>
  </CardBody>
</Card></li>)}
  </ul>}

          </AlertDialogBody>
           
        </AlertDialogContent>
      </AlertDialog>
     <ul>
      <li className='hide'>
        <ul>
        <li>
            <Link  to="/resource-files"  >Resource Files</Link>
            
          </li>
          <li>
            <Link  to="/ekana-academy">Ekana Academy</Link>
            
          </li>
          <li>
          <Link  to="/start-a-bussines">Start a Business</Link>
            
            
          </li>
           
        </ul>
      </li>
      <li>
        <ul>
          <li    >
          <span onClick={()=>setOpenDialog(true) } className='hoverable'> {
  (selectedOffice === null || selectedOffice.Name === undefined) ?
    'Setup Delivery Location' :
    selectedOffice.Name+ ',' + selectedOffice.Pincode
} </span> 
          </li>
          <li>
          { 
          
          <Link to="/login"  >
            <span style={{display:'flex',flexDirection:'row'}}>
            <img src='https://cdn-icons-png.flaticon.com/128/3899/3899618.png' style={{height:'20px',marginInline:'5px'}} alt='user' /> {localStorage.getItem('user')==null ? 'Sign In' : (JSON.parse(localStorage.getItem('user')).name ?? 'Sign In')}
                      </span>
            
            </Link>
         } </li>
           


        </ul>
      </li>
     </ul>
    </div>
  )
}

export default TopHeader
