import React from 'react'
import Header from '../Header/Header'
import Body from '../Body/Body'
import Footer from '../Footer/Footer'



function Home(props) {
  return (
    <div className="App">
    <Header isAuthenticated={props.isAuthenticated} />
    <div style={{"paddingTop":'110px'}}></div>
     
    <Body/>
    <Footer/>


</div>
  )
}

export default Home
