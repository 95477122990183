import React from 'react'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import UnderDevelopment from '../../Products/UnderDevelopment'

function ShippingProcess() {
  return (
    <div className='App'>
      <Header/>
        <div className='main__section container policy-box' style={{textAlign:'left'}}>
            <h1 className='text-center'>Shipping Process</h1> 
<hr/>
<p>At Ekana Herbs Private Limited, we are committed to providing exceptional service to our valued customers. This includes ensuring a smooth and efficient shipping process for all orders. Please review the following shipping policy carefully before making a purchase:</p>

<h2>1. Shipping Methods:</h2>
<p>We offer single shipping methods by road to accommodate the diverse needs of our customers. This method typically takes minimum 4 to maximum 8 business days for domestic orders.</p>

<p><strong>We do not take and ship international orders.</strong></p>

<h2>2. Order Processing Time:</h2>
<p>Orders are typically processed and shipped within 1 business day of payment confirmation. However, please allow for additional processing time during peak seasons or promotional periods.</p>

<h2>3. Shipping Costs:</h2>
<p>Shipping costs are calculated based on the shipping method, the destination of the package, and the weight and dimensions of the items ordered. Customers can view the shipping costs during the checkout process before finalizing their purchase.</p>

<h2>4. Tracking Information:</h2>
<p>Once your order has been processed and shipped, you will receive a shipping confirmation email to track the status of your package and estimate the delivery date. You can also contact us via email at <a href="mailto:support@ekanaherbs.com">support@ekanaherbs.com</a> or by phone at 9651661261.</p>

<h2>5. Delivery Timeframes:</h2>
<p>Delivery timeframes vary depending on the shipping method and the destination of the package. While we make every effort to ensure timely delivery, please note that unforeseen circumstances such as weather delays or customs inspections may impact delivery times.</p>

<h2>6. Shipping Restrictions:</h2>
<p>Certain products may be subject to shipping restrictions based on local regulations or carrier policies. We recommend reviewing any applicable restrictions before placing your order to avoid any complications.</p>

<h2>7. Returns and Exchanges:</h2>
<p>For information regarding returns and exchanges, please refer to our <a href="#returns">Returns Policy</a>.</p>

<h2>8. Contact Information:</h2>
<p>If you have any questions or concerns regarding our shipping policy, please don't hesitate to contact our customer service team. You can reach us via email at <a href="mailto:support@ekanaherbs.com">support@ekanaherbs.com</a> or by phone at 9651661261.</p>

<h2>9. Policy Updates:</h2>
<p>We reserve the right to update or modify our shipping policy at any time without prior notice. Any changes will be effective immediately upon posting on our website. (<a href="#website">Visit our website</a> for more details.)</p>

<p>By placing an order with Ekana Herbs Private Limited, you agree to comply with our shipping policy and acknowledge that you have read and understood its terms and conditions. Thank you for choosing Ekana Herbs Private Limited. We appreciate your business!</p>

        </div>
      <Footer/>
    </div>
  )
}

export default ShippingProcess
