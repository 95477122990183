import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel'; 
import ProductImage from '../Pages/Products/ProductImage';
import Youtube from '../svgs/Youtube';
import Timing from '../svgs/Timing';
import Customer from '../svgs/Customer';
import Mail from '../svgs/Mail';
import Web from '../svgs/Web';
 
 
function HeroCarousel(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
      <div className='carousel-skeletal cs1'>
        <ul>
          <li style={{backgroundColor:"#bf9f92"}}>
          <img src="https://portal.ekanaherbs.com/custom/images/banner-hf.png"/>
          </li>
          <li style={{backgroundColor:"#bf9f92"}}>
          <h3 style={{fontWeight:'bolder'}}>Revolutionize wellness with EKANA Herbs: Pure, Potent, Proven. Empowering individuals and communities towards holistic prosperity.</h3>
          <br/>
            
            <p><strong>#PurePotentProven</strong></p>
          </li>
        </ul>
        
        

      </div>
         
      </Carousel.Item>
      <Carousel.Item>
      <div className='carousel-skeletal cs2'>
      <ul>
          <li style={{backgroundColor:"#091c61"}}>
          <img src="https://portal.ekanaherbs.com/custom/images/banner-contact.png"/>
          </li>
          <li style={{backgroundColor:"#091c61"}}>
           <ul style={{display:'flex',flexDirection:'column',alignItems:'start',justifyContent:'center'}}>
            <li style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBlock:'5px'}} > <Timing color="#FFF"/><span className='icon-text'>10:00 am - 07:00 am (Mon. to Sat.)</span></li>
            <li  style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBlock:'5px'}} ><Web color="#FFF"/> <span className='icon-text'>www.ekanaherbs.com</span></li>
            <li  style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBlock:'5px'}} ><Customer  color="#FFF"/> <span className='icon-text'>0512-4059723</span></li>
            <li  style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBlock:'5px'}} ><Mail color="#FFF"/>  <span className='icon-text'>support@ekanaherbs.com</span></li>
            </ul>
          </li>
        </ul>
      </div>
         
      </Carousel.Item>
      <Carousel.Item>
      <div className='carousel-skeletal cs3'>
      <ul>
          <li  style={{backgroundColor:"#c1cffe"}}>
          <img src="https://portal.ekanaherbs.com/custom/images/banner-bharat.jpeg"/>
          </li>
          <li style={{backgroundColor:"#c1cffe"}}>
            <h3 style={{fontWeight:'bolder'}}>Enhance health with EKANA Herbs for personal and societal prosperity through their system and products.</h3>
            
            
            <p><strong>#HealthIsWealth #ContributeToWellness #BuildABetterFuture</strong></p>
          </li>
        </ul>
      </div>
         
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroCarousel;