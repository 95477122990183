
import React from 'react'
import { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import axios from '../auth/defaultr-axios';
import { useDisclosure } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'
 
 

function Category() {
  
  const [categories, setCategories] = useState([]);
  const { isOpen: isChakraOpen, onOpen: chakraOnOpen, onClose: chakraOnClose } = useDisclosure();
const [openModalIndex, setOpenModalIndex] = useState(null);

const openCustomModal = (index) => {
  setOpenModalIndex(index);
};

const closeCustomModal = () => {
  setOpenModalIndex(null);
};

const handleCustomModalOpen = (index) => {
  openCustomModal(index);
  chakraOnOpen();
};

const handleChakraModalClose = () => {
  closeCustomModal();
  chakraOnClose();
};
  const searchCat = (cat) => { 
    // Redirect to the products page with the search query
    window.location.href = `/products?category=${cat}`;
  }; 
  const images=['https://portal.ekanaherbs.com/custom/images/announce11.png','https://portal.ekanaherbs.com/custom/images/announce11.png','https://portal.ekanaherbs.com/custom/images/announce11.png','https://portal.ekanaherbs.com/custom/images/announce22.png'];

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);
  

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.ekanaherbs.com/api/v1/categories');
      setCategories(response.data); // Assuming the data is an array
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <> 
    <div className='categories'>
    <h2>Our Product Categories</h2>
      <ul> 
    {categories.map((category, tk) => (
      <li onClick={(category.category_name.startsWith("Baby") || category.category_name.startsWith("Beauty")) ? () => openCustomModal(tk) : () => searchCat(category.category_name)} className='hfont' key={category.id}>
        <img src={`https://portal.ekanaherbs.com/custom/images/products/${category.category_image}`} alt="Category Image" />
        <p style={{ textAlign: 'center' }}>{category.category_name}</p>

        <Modal key={tk} onClose={closeCustomModal} isOpen={openModalIndex === tk} isCentered>
          <ModalOverlay />
          <ModalContent style={{backgroundImage: `url(${images[tk]})`, backgroundSize: '100% 100%', height: '450px'}}>

          <ModalHeader style={{backgroundColor:'transparent !important'}}></ModalHeader>
          <ModalCloseButton style={{color:'orange'}} />
          <ModalBody style={{color:'green'}}>
            
          </ModalBody>
           
        </ModalContent>
        </Modal>
      </li>
    ))}
    
     
      </ul>
    </div>
    </>
  )
}

export default Category
