import React from 'react'

function Faqs() {
  return (
    <div>
      Our FAQs Section
    </div>
  )
}

export default Faqs
