import React from 'react'

function OurBrand() {
  return (
    <div>
      <h1>Our Brand</h1>
    </div>
  )
}

export default OurBrand
